import React  from "react"
import styled from "styled-components"
import Title from "../core/WhiteTitle"
import BackgroundImage from "gatsby-background-image"
import { graphql, StaticQuery } from "gatsby"
import BlueLineImg from "../../resources/images/brewery/blue-line.svg"
import { Fade } from "react-reveal"
import OverlayBottom from "../core/OverlayBottom"
import MobileBottomOverlay from "../core/MobileBottomOverlay"
import LineMobile from "../../resources/images/beer-landing/white-line-dotted-mobile.svg"
import LineDesktop from "../../resources/images/beer-landing/white-line-dotted-desktop.svg"

const BackgroundStyled = styled.div`
  position: relative;
  height: 580px;
  margin-top: -138px;
  text-align: center;
  height: 420px;
  overflow: visible;
  @media (min-width: 768px) {
    height: 480px;
  }
`
const BackgroundImageStyled = styled(BackgroundImage)`
  width: 100vw;
  height: 100%;
`
const VerticalAlign = styled.div`
  width: 100%;
  position: absolute;
  top: 160px;
  z-index: 2;
  @media (min-width: 768px) {
    top: 213px;
  }
`
const BlueLine = styled.img`
  padding-top: 25px;
`

const WhiteLine = styled.img`
  margin-top: 30px;
  @media (min-width: 768px) {
    margin-top: 22px;
  }
`

const Mobile = styled.div`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`
const Desktop = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`

const HomeHero = () => (
  <StaticQuery
    query={graphql`
      query {
        background: file(
          relativePath: { eq: "beer-landing/beers-hero-opt.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 5000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.background.childImageSharp.fluid;

      return (
        <BackgroundStyled>
          <BackgroundImageStyled
            fluid={imageData}
            critical={true}
            durationFadeIn={100}
          >
            <Mobile>
              <MobileBottomOverlay />
            </Mobile>
            <Desktop>
              <OverlayBottom />
            </Desktop>
          </BackgroundImageStyled>
          <VerticalAlign>
            <Fade duration={500} delay={250}>
              <Title title={"Beers"} />
            </Fade>
            <Fade duration={500} delay={500}>
              <BlueLine src={BlueLineImg} alt="blue decorative line" />
            </Fade>
            <Fade duration={500} delay={500}>
              <Mobile>
                <WhiteLine src={LineMobile} alt="white decorative line" />
              </Mobile>
              <Desktop>
                <WhiteLine src={LineDesktop} alt="white decorative line" /> 
              </Desktop>
            </Fade>
          </VerticalAlign>
        </BackgroundStyled>
      )
    }}
  />
)

export default HomeHero
