import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Row, Col, Container } from "styled-bootstrap-grid"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import Img from "gatsby-image"
import Button from "../core/Button"
import HorizontalLine from "../../resources/images/beer-landing/horizontal-line-dotted.svg"

const BeersContainer = styled.div`
  max-width: 100vw;
`

const Beer = styled.div`
  color: ${colors.white};
  text-align: center;
  box-sizing: border-box;
  padding: 0 33px;
  position: relative;
  z-index: 2;
  @media (min-width: 768px) {
    text-align: ${props => props.textAlign};
    max-width: 1440px;
    margin: auto;
  }
`

const ABV = styled.div`
  ${fonts.gilroyBold};
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 2.77px;
  margin-bottom: 13px;
  
`

const Name = styled.h2`
  ${fonts.swissBlackExtended};
  font-size: 32px;
  line-height: 34px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 25px;
  @media(min-width: 768px) {
    margin-bottom: 10px;

  }
  @media(min-width: 1200px) {
    font-size: 42px;
    line-height: 64px;
    letter-spacing: 2.71px;
    margin-bottom:20px;

  }
`

const SubInfo = styled.div`
  @media (min-width: 992px) {
    padding: ${props =>
      props.order === "first" ? "0 95px 0 0" : " 0 0 0 95px"};
    display: flex;
    justify-content: ${props =>
      props.order === "first" ? "flex-end" : " flex-start"};
    flex-wrap: wrap;
  }
`

const Flavor = styled.h3`
  ${fonts.swissBlackExtended};
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.94px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    max-width: 414px;
  }
`

const Description = styled.div`
  ${fonts.gilroyRegular};
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 46px;
  strong {
    ${fonts.gilroyBold};
  }
  b {
    ${fonts.gilroyMedium};
  }
  i {
    font-style: italic;
  }

  @media (min-width: 768px) {
    max-width: 380px;
  }
`

const ColStyled = styled(Col)`
  padding: 0;
  position: relative;
  margin-bottom: 78px;
  :last-of-type {
    margin-bottom: 40px;
  }
  @media (min-width: 768px) {
    margin-bottom: 160px;
    :last-of-type {
    margin-bottom: 100px;
  }
  }
`

const StyledImg = styled(Img)`
  max-height: 385px;
  margin-bottom: 30px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  }
  @media (min-width: 768px) {
    max-height: 608px;
  }
`

const Background = styled(Img)`
  max-height: 100%;
  @media (min-width: 768px) {
    height: 100%;
    width: 100%;
    max-height: ${props => (props.top ? "621px" : "729px")};
  }
`

const BackgroundContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  ${props => props.top && "top: -80%"};
  ${props => props.bottom && "bottom: -56%"};
  @media (min-width: 768px) {
    height: ${props => (props.top ? "621px" : "729px")};
    ${props => props.bottom && "bottom: -76%"};
  }
`

const BeerCol = styled(Col)`
  padding: 0;
`

const RowStyled = styled(Row)`
  max-width: 100vw;
  margin: auto;
`

const Horizontal = styled.div`
  display: none;
  @media(min-width: 768px) {
    display: block;
    margin: ${props => props.order === "first" ? "0 -30% 20px 0" : "0 0 20px -30%"}
  }
  @media(min-width: 992px) {
    display: block;
    margin: ${props => props.order === "first" ? "0 -24% 40px 0" : "0 0 40px -24%"}
  }
`

const ButtonContainer = styled.div`
  width: 100%;
`

const Beers = props => {
  const { beers, mobile, desktop } = props
  const [width, setWidth] = useState()

  const handleResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== "undeifned") {
      setWidth(window.innerWidth)
    }
  }, [])

  useEffect(() => {
    if (typeof window !== "undeifned") {
      window.addEventListener("resize", handleResize)
    }
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [width])

  return (
    <BeersContainer>
      <RowStyled>
        {beers.map(({ node: beer }, index) => {
          return (
            <ColStyled key={index}>
              {index % 3 === 1 && (
                <BackgroundContainer top>
                  <Background
                    fluid={
                      width < 586
                        ? mobile[0].childImageSharp.fluid
                        : desktop[0].childImageSharp.fluid
                    }
                    alt="Ocean Background"
                  />
                </BackgroundContainer>
              )}
              <Beer textAlign={index % 2 ? "right" : "left"}>
                <RowStyled>
                  <BeerCol md={6} mdOrder={index % 2 ? "last" : "first"} lg={5}>
                    {beer.images[0] && <StyledImg
                      fluid={beer.images[0].localFile.childImageSharp.fluid}
                    />}
                  </BeerCol>
                  <BeerCol md={6} lg={6}>
                    <SubInfo order={index % 2 === 0 ? "last" : "first"}>
                      <ABV>4.3% pale ale</ABV>
                    </SubInfo>
                    <Name>{beer.title}</Name>
                    <Horizontal order={index % 2 === 0 ? "last" : "first"}>
                      <img src={HorizontalLine} alt="decorative horizontal line" />
                    </Horizontal>
                    <SubInfo order={index % 2 === 0 ? "last" : "first"}>
                      <Flavor>
                        Packed with flavours to savour, North Point is a beer
                        that takes some beating.
                      </Flavor>
                      <Description
                        dangerouslySetInnerHTML={{
                          __html: beer.descriptionHtml,
                        }}
                      />
                      <ButtonContainer>
                        <Button iscentered text={"more info"} link={`/beers-detail`}/>
                      </ButtonContainer>
                    </SubInfo>
                  </BeerCol>
                </RowStyled>
              </Beer>
              {index % 3 === 1 && index !== beers.length - 1 && (
                <BackgroundContainer bottom>
                  <Background
                    fluid={
                      width < 586
                        ? mobile[1].childImageSharp.fluid
                        : desktop[1].childImageSharp.fluid
                    }
                    alt="Ocean Background"
                  />
                </BackgroundContainer>
              )}
            </ColStyled>
          )
        })}
      </RowStyled>
    </BeersContainer>
  )
}

export default Beers
