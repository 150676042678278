import React from "react"
import styled from "styled-components"
import Hero from "../components/beers/Hero"
import Layout from "../components/core/Layout"
import SEO from "../components/core/Seo"
import PrivateRoute from "../router/PrivateRoute"
import DarkBlock from "../components/beers/DarkBlock"
import Beers from "../components/beers/Beers"
import Intro from "../components/beers/Intro"
import BeersCarousel from "../components/beers/BeersCarousel"
import Location from "../components/beers/Location"

const LayoutStyled = styled(Layout)`
  margin: 0;
`

const BeerPage = (props) => {
  const path = props.location.pathname
  const { data } = props;
  const {beers, background, landscape, portrait, page } = data;
  // const beersProp = {beers: beers.edges, mobile: [BackgroundMobile1, BackgroundMobile2], desktop: [ BackgroundDesktop1, BackgroundDesktop2]};
  const carouselProp = {beers: beers.edges, background: background};
  const locationProp = {image1: landscape, image2: portrait}

  return (
    <LayoutStyled>
      <SEO
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
      />
      <Hero />
      <DarkBlock>
        <Intro />
        <BeersCarousel {...carouselProp} />
        <Location {...locationProp}/>
        {/* <Beers {...beersProp}/> */}
      </DarkBlock>
    </LayoutStyled>
  )
}

function beer(props) {
  return <PrivateRoute component={BeerPage} {...props} />
}

export default beer

export const pageQuery = graphql`
  query BeersPageQuery {
    page: contentfulLandingPage(slug: { eq: "beers" }) {
      id
      metaTitle
      metaDescription
    }
    beers: allShopifyProduct(filter: {productType: {eq: "Beer"}}) {
      edges {
        node {
          id
          handle
          title
          handle
          description
          images {
            id
            localFile {
              url
              childImageSharp {
                fluid(maxWidth: 356, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          metafields {
            key
            value
          }
          id
          shopifyId
          handle
          title
          descriptionHtml
          description
          productType
          tags
          variants {
            id
            price
            shopifyId
            image {
              id
              localFile {
                extension
              }
            }
            title
            selectedOptions {
              name
              value
            }
          }
          options {
            id
            values
            name
            shopifyId
          }
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    background: file(relativePath: { eq: "beer-landing/beers-background.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxHeight: 916) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    landscape: file(relativePath: { eq: "beer-landing/watering-hole-landscape.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1296) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    portrait: file(relativePath: { eq: "beer-landing/watering-hole-portrait.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 728) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
