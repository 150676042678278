import React, { useState, useRef } from "react"
import styled from "styled-components"
import Product from "./SingleProduct"
import { Container } from "styled-bootstrap-grid"
import RightArrow from "../../resources/images/ui/right-arrow.svg"
import LeftArrow from "../../resources/images/ui/left-arrow.svg"
import Slider from "react-slick/lib"

const ProductContainer = styled(Container)`
  padding-top: 300px;
  padding-bottom: 50px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 35px;
  z-index: 10;
  @media (max-width: 768px) {
    padding: 0px;
  }
`
const CarouselArrows = styled.div`
  padding-top: 50px;
  text-align: center;
`
const LeftArrowStyled = styled.img`
  width: 40px;
  padding-right: 15px;
`
const RightArrowStyled = styled.img`
  width: 40px;
  padding-left: 15px;
`
const ArrowSlideLeft = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
`
const ArrowSlideRight = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
`

const StyledSlider = styled(Slider)`
    max-width: 500px;
    margin: 0 auto;
    overflow: visible;
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
`

const ProductCarousel = ({ products }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const slideRef = useRef()

  const changeSlide = diff => {
    const index = slideIndex + diff

    slideRef.current.slickGoTo(index)
  }
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipe: true,
    draggable: true,
    swipeToSlide: false,
    centerMode: false,
    beforeChange: (current, next) => setSlideIndex(next),
  }
  return (
    <ProductContainer>
      <StyledSlider {...settings} ref={slideRef}>
        {products.map(({ node: product }, index) => {
          return (
            <React.Fragment key={index}>
              <Product
                product={product}
              />
            </React.Fragment>
          )
        })}
      </StyledSlider>
      <CarouselArrows>
        <ArrowSlideLeft>
          <LeftArrowStyled src={LeftArrow} onClick={() => changeSlide(-1)}/>
        </ArrowSlideLeft>
        <ArrowSlideRight>
          <RightArrowStyled src={RightArrow} onClick={() => changeSlide(1)}/>
        </ArrowSlideRight>
      </CarouselArrows>
    </ProductContainer>
  )
}

export default ProductCarousel
