import React from "react"
import styled from "styled-components"
import { Row, Col, Container } from "styled-bootstrap-grid"
import SingleProduct from "./SingleProduct"

const ProductContainer = styled(Container)`
  padding-top: 200px;
  padding-bottom: 50px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: -35px;
  z-index: 10;
`
const ColStyled = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  @media (min-width: 768px) {
    display: ${props => (props.mobileonly ? "none" : "block")};
  }
  @media (max-width: 768px) {
    display: ${props => (props.dektoponly ? "none" : "block")};
  }
`
const RowStyled = styled(Row)`
  margin-left: 0px;
  margin-right: 0px;
  width: ${props => (props.fullwidth ? "100%" : "")};
  height: ${props => (props.fullwidth ? "100%" : "")};
`

const ProductList = ({ products }) => {
  return (
    <ProductContainer>
      <RowStyled>
        {products.map(({ node: product }, index) => {
          return (
            <ColStyled md={4} key={index}>
              <SingleProduct
                product={product}
              />
            </ColStyled>
          )
        })}
      </RowStyled>
    </ProductContainer>
  )
}

export default ProductList
