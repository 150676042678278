import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import Img from "gatsby-image"
import { Container, Row, Col } from "styled-bootstrap-grid"
import Button from "../core/Button"
import WavesImage from "../../resources/images/icons/waves.svg";
import LineDesktop from "../../resources/images/beer-landing/white-line-dotted-desktop.svg"

const LocationContainer = styled.div`
  max-width: 100vw;
  margin-top: 170px;
  padding-bottom: 100px;
`

const TitleContainer = styled.div`
  position: relative;
  z-index: 2;
  @media (min-width: 992px) {
    padding-left: 105px;
  }
`

const Title = styled.h2`
  ${fonts.swissBlackExtended}
  color: ${props => (props.outlined ? "transparent" : colors.white)};
  text-transform: uppercase;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 2px;
  margin-top: 0px;
  margin-bottom: ${props => props.outlined ? "0px" : "6px"};
  ${props =>
    props.outlined &&
    `-webkit-text-stroke-width: 1.5px;-webkit-text-stroke-color: ${colors.textGrey};`}
  @media(min-width: 576px) {
    max-width: 520px;
    font-size: 60px;
    line-height: 62px;
    letter-spacing: 6px;
  }
`

const Section = styled.div``

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`

const FirstImage = styled(Img)`
  max-width: 648px;
  left: 42%;
  margin-left: 7px;
  margin-top: -151px;

`

const Waves = styled.img`
  position: absolute;
  bottom: 46px;
  right: -34px;
  z-index: 2;
  display: none;

  @media(min-width: 991px) {
    display: block;
  }
`

const SecondImage = styled(Img)`
  /* left: -7%; */
  max-width: calc(100vw - 30px);
  margin: auto;
  @media (min-width: 576px) {
    max-width: 314px;
  }
  @media (min-width: 991px) {
    margin-top: -156px;
  }
  @media (min-width: 1440px) {
    left: -27%;
  }
`

const RowStyled = styled(Row)`
  padding-top: 80px;
  display: block;
  @media (min-width: 576px) {
    display: flex;
    box-sizing: border-box;
  }
`

const ColStyled = styled(Col)`
  padding-left: 0;
  padding-right: 0;
  max-width: 100vw;
`

const TextContainer = styled.div`
  max-width: 370px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  height: 100%;
  padding: 40px 15px 0px;
  padding-top: 40px;
  @media (min-width: 576px) {
    padding: 0;
    padding-left: 15px;
  }
  @media (min-width: 768px) {
    padding: 0;
  }
`

const Text = styled.p`
  ${fonts.gilroyRegular};
  font-size: 15px;
  color: ${colors.textGrey};
  line-height: 21px;
  padding-bottom: 50px;
`

const ButtonContainer = styled.div`
  @media (min-width: 576px) {
    padding-bottom: 36px;
  }
`

const WhiteLine = styled.img`
  display: none;
  position: absolute;
  top: 10%;
  transform: rotate(90deg);
  right: -5%;
  @media (min-width: 768px) {
    display: block;
  }
  @media (min-width: 991px) {
    top: 15%;
    right: 10%;
  }
  @media (min-width: 1440px) {
    right: 40%;
  }


`

const Location = props => {
  const { image1, image2 } = props
  return (
    <LocationContainer>
      <Container>
        <TitleContainer>
          <Title>Find your<br/> nearest</Title>
          <Title outlined>Watering<br/> Hole</Title>
        </TitleContainer>
        <Section>
          <Row>
            <Col>
              <ImageContainer>
                <FirstImage fluid={image1.childImageSharp.fluid} alt="Surfer" />
                <Waves src={WavesImage} alt="waves" />
              </ImageContainer>
            </Col>
            <Col>
              <RowStyled>
                <ColStyled sm={6} md={6}>
                  <ImageContainer>
                    <SecondImage
                      fluid={image2.childImageSharp.fluid}
                      alt="Beach"
                    />
                    <WhiteLine src={LineDesktop} alt="White decorative line" />
                  </ImageContainer>
                </ColStyled>
                <ColStyled sm={6} md={6}>
                  <TextContainer>
                    <div>
                      <Text>
                        Say ahoy to our shipmates ~ We supply only a limited number of trade customers so we can really support companies who share our vision and passion for the British coast.                      </Text>
                      <ButtonContainer>
                        <Button text={"Set sail"} link={"/shipmates"}></Button>
                      </ButtonContainer>
                    </div>
                  </TextContainer>
                </ColStyled>
              </RowStyled>
            </Col>
          </Row>
        </Section>
      </Container>
    </LocationContainer>
  )
}

export default Location
