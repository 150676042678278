import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import ProductList from "../home/ProductList"
import ProductCarousel from "../home/ProductCarousel"
import Overlay from "../core/OverlayTop"

const BeersContainer = styled.div`
  max-width: 100vw;
  position: relative;
  height: 916px;
`

const DesktopOnlyCarousel = styled.div`
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
`
const MobileOnlyCarousel = styled.div`
  display: block;
  @media (min-width: 992px) {
    display: none;
  }
`

const Background = styled(Img)`
  max-height: 100%;
  @media (min-width: 768px) {
    height: 100%;
    width: 100%;
    max-height: 916px;
  }
`

const BackgroundContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  @media (min-width: 768px) {
    height: 916px;
  }
`

const BeersCarousel = props => {
  const { beers, background } = props
  const slicedBeers = beers.slice(0, 3)
  return (
    <BeersContainer>
      <BackgroundContainer>
        <Background
          fluid={background.childImageSharp.fluid}
          alt="Ocean Background"
        />
        <Overlay />
      </BackgroundContainer>
      <DesktopOnlyCarousel>
        <ProductList products={slicedBeers} />
      </DesktopOnlyCarousel>
      <MobileOnlyCarousel>
        <ProductCarousel products={slicedBeers} />
      </MobileOnlyCarousel>
    </BeersContainer>
  )
}

export default BeersCarousel
