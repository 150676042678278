import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import Anchor from "../../resources/images/ui/anchor.svg"

const IntroContainer = styled.div`
  width: 100%;
  position: relative;
`

const Catch = styled.h2`
  ${fonts.gilroyBold};
  text-transform: uppercase;
  color: ${colors.white};
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 1.2px;
  text-align: center;
  position: absolute;
  bottom: -100%;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 58px;
  img {
    margin-top: 20px;
  }
`

const Text = styled.div`
  ${fonts.gilroyRegular};
  color: ${colors.textGrey};
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  p {
    max-width: 540px;
  }
`

const Intro = () => {
  return (
    <IntroContainer>
      <Text>
        <p>
          Here at LAS, we brew a defiantly small range of beers that we've dedicated our recent years to perfecting. And
          although all uniquely different, they share a balanced, flavoursome, highly drinkable quality that we've
          rarely experienced in other beers. We invite you to lose yourself in our range, from our flagship IPA to our
          core range and limited editions.
        </p>
      </Text>
      <Catch>
        <div>Catch of the day</div>
        <img src={Anchor} alt="Catch of the day" />
      </Catch>
    </IntroContainer>
  )
}

export default Intro
