import React, { Component } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { isCartUpdating, isCartUpdated } from "../../store/reducers/index"
import { addItemAction } from "../../store/actions/cart/index"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import get from "lodash/get"
import {
  getOptionsData,
  getSelectedVariant,
} from "../../services/product/options"

const ProductButton = styled.button`
  cursor: pointer;
  width: 50%;
  background: transparent;
  color: ${colors.white};
  border-radius: 0;
  ${fonts.gilroyBold};
  font-size: 12px;
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 15px;
  border-left: ${props => (props.info ? "1px solid" + colors.mint : "none")};
  border-right: 1px solid ${colors.mint};
  border-bottom: 1px solid ${colors.mint};
  border-top: 1px solid ${colors.mint};
  letter-spacing: 3px;
  transition: background 0.1s, border 0.2s, color 0.1s;
  -webkit-transition: background 0.1s, border 0.1s, color 0.1s;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    background: ${colors.mint};
    border: 1px solid ${colors.mint};
    color: ${colors.navy};
  }
  @media (max-width: 768px) {
    padding-top: 14px;
    padding-bottom: 14px;
  }
`

class BuyNow extends Component {
  state = {
    options: {},
    variant: null,
    location: undefined,
  }
  getProduct() {
    return get(this, "props.product")
  }

  addToCart = (variantImage, e) => {
    e.preventDefault()

    const { isUpdating } = this.props
    const { variant } = this.state
    if (isUpdating) {
      return
    }
    this.props.addItemAction(variant, 1, variantImage)
  }

  handleClick = (variantImage, e) => {
    this.addToCart(variantImage, e)
  }

  componentDidMount() {
    const product = this.getProduct()
    const optionsData = getOptionsData(product)

    if (typeof window !== undefined) {
      this.setState({
        options: optionsData,
        variant: getSelectedVariant(product, optionsData),
        location: window.location.href,
      })
    }
  }

  render() {
    const { images, soldout } = this.props
    const { handleClick } = this
    // @todo dynamic variant image based on selected options
    let variantImage

    if (images.length > 0) {
      const firstImage = images[0]

      if (firstImage.localFile) {
        variantImage = firstImage.localFile.url
      }
    }

    return (
      <ProductButton
        disabled={soldout ? 1 : 0}
        onClick={e => {
          handleClick(variantImage, e)
        }}
      >
        {soldout? "Sold Out" : "Buy Now"}
      </ProductButton>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAdding: isCartUpdating(state),
    isAdded: isCartUpdated(state),
  }
}

const mapDispatchToProps = {
  addItemAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyNow)
