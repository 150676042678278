import React from "react"
import styled from "styled-components"
import fonts from '../../styles/fonts'
import colors from '../../styles/colors'

const DarkBlockContainer = styled.div`
  background: ${colors.darkNavyBackground};
  padding-top: 85px;
  max-width: 100vw;
  overflow: hidden;
  @media (min-width: 768px) {
    padding-top: 125px;
  }
`

const DarkBlock = ({children}) => {
  return (
    <DarkBlockContainer>
      {children}
    </DarkBlockContainer>
  )
}

export default DarkBlock
